<template>
  <div class="mb-2">
    <v-row   class="mt-2 mb-2 ">
      <v-col cols="12" class="justify-center d-flex">
       

        <v-btn
        
        @click="expressionTable = false, admisitrativeTable = true"
        :disabled="admisitrativeTable"
        >
         ADMINISTRATIVE Table
        </v-btn>

        <v-btn
        @click="expressionTable = true, admisitrativeTable = false"
        :disabled="expressionTable"
        >
          Expression Of Interest Table
        </v-btn>
      </v-col>

    </v-row>
    <v-data-table
      :headers="Headers"
      :items="adminstratives"
      class="elevation-1 tb px-2"
      :search="search"
      v-if="admisitrativeTable"
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-toolbar flat>
            <b>ADMINISTRATIVE </b>
          </v-toolbar>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-row>
      </template>

      <template #item.actions="{ item }">
        <v-btn
          :to="{ name: 'Administrative', params: { editUser: { ...item } } }"
          icon
          x-small
          class="mr-2"
          title="Click to edit"
          color="black"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-icon
          icon
          small
          @click="del(item)"
          title="Click to delete "
          color="error"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <vue-excel-xlsx
    v-if="admisitrativeTable"
      class="mt-2 text-center"
      :data="adminstratives"
      :columns="exceldatas"
      :filename="'Administrative Data'"
      :sheetname="'Administrative Sheets'"
    >
      <v-btn small rounded class="white--text" color="primary">
        Export to Excel</v-btn
      >
    </vue-excel-xlsx>

    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <div v-if="expressionTable" class="mt-3 mb-3">
      <v-data-table
      :headers="exHeaders"
      :items="expression"
      class="elevation-1 tb px-2"
      :search="search"
    >
      <template v-slot:top>
        <v-row justify="end" no-gutters>
          <v-toolbar flat>
            <b>Expression Of Intrest </b>
          </v-toolbar>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-row>
      </template>

      <template #item.actionss="{ item }">
        <v-btn
          :to="{ name: 'express', params: { editExpress: { ...item }, expressForm: true, adminstrativesForm: false  } }"
          icon
          x-small
          class="mr-2"
          title="Click to edit"
          color="black"
          ><v-icon>mdi-pencil</v-icon></v-btn
        >
        <v-icon
          icon
          small
          @click="delex(item)"
          title="Click to delete "
          color="error"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <vue-excel-xlsx
      class="mt-2 text-center"
      :data="expression"
      :columns="exceldatas2"
      :filename="'Expression Of Intrests Data'"
      :sheetname="'Expression Of Intrests Sheets'"
    >
      <v-btn small rounded class="white--text" color="primary">
        Export to Excel</v-btn
      >
    </vue-excel-xlsx>

    <v-snackbar v-model="snackbar" bottom :color="snackColor">
      <p>{{ snackMsg }}</p>
      <template v-slot:action="{ attrs }">
        <v-btn dark v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
          Close
        </v-btn>
      </template>
    </v-snackbar>

    </div>
  </div>
  
</template>

<script>
import { administrativeData,  ExpressData } from "@/excels";
import { apiClient } from "@/services";
import { snackMixin } from "@/mixins";
export default {
  name: "item",
  mixins: [snackMixin],

  data() {
    return {
      exceldatas: administrativeData,
      exceldatas2: ExpressData,
      search: "",
      Headers: [
        { text: "Meeting Types", value: "MeetingType" },
        { text: "Meeting", value: "Meeting" },
        { text: "Date of Meeting", value: "Date" },
        { text: "No of Attendee", value: "Attendee" },
        { text: "Created By", value: "created_by" },
        { text: "Modified By", value: "modified_by" },
        { text: "Actions", value: "actions" },
      ],
       exHeaders: [
        { text: "Communtiy Name", value: "CommunityName" },
        { text: "Date Submitted", value: "DateSubmitted" },
        { text: "Created By", value: "created_by" },
        { text: "Modified By", value: "modified_by" },
        { text: "Actions", value: "actionss" },
      ],
      adminstratives: [],
      expression: [],
      expressionTable: false,
      admisitrativeTable: true
    };
  },

  created() {
    apiClient
      .get("/administrative?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.adminstratives = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));

    apiClient
      .get("/expression?u=2")
      .then((res) => {
        if (Array.isArray(res.data)) {
          this.expression = res.data;
        } else {
          this.displayMsg(res.data, "error");
        }
      })
      .catch((err) => this.displayMsg(err.message, "error"));
  },

  methods: {
    del(item) {
      const ok = confirm(`Are sure you want to delete this data?`);
      if (ok) {
        this.displayMsg(`Deleting data, please wait...`);
        apiClient
          .delete(`/administrative?${item.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(`Data not successfully deleted`, "error");
              return;
            } else {
              this.adminstratives = this.adminstratives.filter(
                (pt) => pt.id !== item.id
              );
              this.displayMsg(`Data successfully deleted `);
            }
          })
          .catch((err) => this.displayMsg(`${err.message}`, "error"));
      }
    },

    delex(item) {
      const ok = confirm(`Are sure you want to delete this data?`);
      if (ok) {
        this.displayMsg(`Deleting data, please wait...`);
        apiClient
          .delete(`/expression?${item.id}`)
          .then((res) => {
            if (!res.data.ok) {
              this.displayMsg(`Data not successfully deleted`, "error");
              return;
            } else {
              this.expression = this.expression.filter(
                (pt) => pt.id !== item.id
              );
              this.displayMsg(`Data successfully deleted `);
            }
          })
          .catch((err) => this.displayMsg(`${err.message}`, "error"));
      }
    },
  },
};
</script>
<style scoped>
.tb tr,
.tb th {
  border: 1px solid black;
}
</style>
